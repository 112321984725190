import { Main } from 'ui/components/4-habitats/Main/Main';
import { ModuleFeature } from 'features/Modules/DynamicModule/DynamicModuleFeature';
import { Footer } from 'ui/components/4-habitats/Footer/Footer';
import { footerMapper } from 'helpers/mappers';
import { usePageContext } from 'application/contexts/PageContext';
import { useSiteContext } from 'application/contexts/SiteContext';
import { useDictionaryContext } from 'application/contexts/DictionaryContext';

export const ContentPageFeature: React.FC<Content.ContentPage> = ({ properties }) => {
	const pageContext = usePageContext();
	const siteContext = useSiteContext();
	const dictionary = useDictionaryContext();
	const footerArgs = footerMapper(pageContext.footer, siteContext.settings.globalTheme?.setTheme, dictionary);
	const { pageHeader, pageSections } = properties ?? {};

	const goToLinkLabel = dictionary.getValue('Link.GoToLink');
	const ctaLinkLabel = dictionary.getValue('Link.CTALink');

	return (
		<>
			<Main
				header={pageHeader.map((headerModule, index) => (
					<ModuleFeature key={index} contentModule={headerModule} />
				))}
			>
				{pageSections.map((sectionModule, index) => (
					<ModuleFeature key={index} contentModule={sectionModule} />
				))}
			</Main>
			{footerArgs && <Footer {...footerArgs} goToLinkLabel={goToLinkLabel} ctaLinkLabel={ctaLinkLabel} />}
		</>
	);
};
